import React from 'react';
import logo from '../../images/logo.jpg';
import styles from './HeaderBanner.module.scss';

const HeaderBanner = () => {
	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Family Removals &amp; Light Haulage</h1>
			<img className={styles.logo} src={logo} alt="logo" />
		</div>
	);
}

export default HeaderBanner;
