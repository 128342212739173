import './App.scss';
import HeaderBanner from './components/HeaderBanner/HeaderBanner';

import img1 from './images/img_1.jpg';

function App() {
  return (
    <div className="App">
      <header>
        <HeaderBanner />
      </header>
      <div className="contentRow">
        <img className="contentRow--image" src={img1} alt="img_1" />
        <div>
          <p>I am a man with a Luton Van based in Brighton. I offer unbeatable value for money from single item to full house moves.</p>
          <p>Family Removals &amp; Light Haulage is a small local firm that can cater for many different needs. We offer a broad range of services including local, nationwide &amp; European deliveries. House removals, clearance and much more.</p>
          <p>Working hours are all week from 8am to 9pm.</p>
          <p>Prices:</p>
          <ul>
            <li>£35 per hours for 1 man</li>
            <li>£45 per hours for 2 men</li>
            <li>£65 per hours for 3 men</li>
            <li>£20 per hours for drive only</li>
          </ul>
          <p>For booking and more information message or call on <a href="tel:07429818510">07429818510</a>.</p>
        </div>
      </div>
    </div>
  );
}

export default App;
